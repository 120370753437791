var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-stack h-stretch gap-5"},[_c('div',{staticClass:"h-stack h-space-between"},[_c('div',{staticClass:"h-stack gap-3 h-start"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedCategory),expression:"selectedCategory"}],staticStyle:{"width":"200px"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedCategory=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',[_vm._v("All")]),_vm._l((_vm.categories),function(category){return _c('option',{key:category._id,domProps:{"value":category}},[_vm._v(" "+_vm._s(category.name)+" ")])})],2),_c('button',{staticClass:"submit",on:{"click":function($event){return _vm.showDialog()}}},[_vm._v("+")])]),_c('button',{staticClass:"add",on:{"click":function($event){return _vm.$router.push('/lifeschool/item/new')}}},[_vm._v(" Add ")])]),_c('div',{ref:"overlay",staticClass:"overlay"},[_c('div',{staticClass:"card dynamic"},[_vm._m(0),_c('div',{staticClass:"content mt-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newCategoryName),expression:"newCategoryName"}],attrs:{"type":"text","placeholder":"name"},domProps:{"value":(_vm.newCategoryName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.newCategoryName=$event.target.value}}})]),_c('div',{staticClass:"footer gap-3 h-space-between mt-3"},[_c('button',{staticClass:"submit",on:{"click":function($event){return _vm.dismiss()}}},[_vm._v("Cancel")]),_c('button',{staticClass:"edit",on:{"click":function($event){return _vm.addCategory()}}},[_vm._v("Add")])])])]),_c('Table',{attrs:{"items":_vm.filteredItems,"defaultColumn":0,"css":'1fr 160px 100px 40px 1fr',"columns":[
      {
        name: 'Name',
        sort: 'alphabet',
        path: ['name'],
      },
      {
        name: 'Category',
        sort: 'alphabet',
        path: ['category', 'name'],
      },
      {
        name: 'URL',
        sort: 'alphabet',
        path: ['url'],
        formatType: 'link',
      },
      {
        name: 'Rating',
        sort: 'numeric',
        path: ['rating'],
      },
      {
        name: 'Description',
        sort: 'alphabet',
        path: ['description'],
      } ]},on:{"rowSelected":function($event){return _vm.$router.push('/lifeschool/item/detail/' + $event._id)}}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header"},[_c('div',[_vm._v("Add Category")])])}]

export { render, staticRenderFns }