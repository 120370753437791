<template>
  <div class="v-stack h-stretch gap-5">
    <div class="h-stack h-space-between">
      <div class="h-stack gap-3 h-start">
        <select v-model="selectedCategory" style="width: 200px">
          <option>All</option>
          <option
            v-for="category in categories"
            :value="category"
            :key="category._id"
          >
            {{ category.name }}
          </option>
        </select>
        <button class="submit" @click="showDialog()">+</button>
      </div>
      <button class="add" @click="$router.push('/lifeschool/item/new')">
        Add
      </button>
    </div>

    <div ref="overlay" class="overlay">
      <div class="card dynamic">
        <div class="header">
          <div>Add Category</div>
        </div>
        <div class="content mt-3">
          <input v-model="newCategoryName" type="text" placeholder="name" />
        </div>
        <div class="footer gap-3 h-space-between mt-3">
          <button @click="dismiss()" class="submit">Cancel</button>
          <button @click="addCategory()" class="edit">Add</button>
        </div>
      </div>
    </div>

    <Table
      :items="filteredItems"
      :defaultColumn="0"
      v-on:rowSelected="$router.push('/lifeschool/item/detail/' + $event._id)"
      :css="'1fr 160px 100px 40px 1fr'"
      :columns="[
        {
          name: 'Name',
          sort: 'alphabet',
          path: ['name'],
        },
        {
          name: 'Category',
          sort: 'alphabet',
          path: ['category', 'name'],
        },
        {
          name: 'URL',
          sort: 'alphabet',
          path: ['url'],
          formatType: 'link',
        },
        {
          name: 'Rating',
          sort: 'numeric',
          path: ['rating'],
        },
        {
          name: 'Description',
          sort: 'alphabet',
          path: ['description'],
        },
      ]"
    >
    </Table>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Table from "@/components/Table.vue";

export default {
  data() {
    return {
      newCategoryName: "",
      categories: [],
      selectedCategory: "All",
      items: [],
    };
  },
  components: {
    Table,
  },
  computed: {
    filteredItems() {
      if (this.selectedCategory == "All") {
        return this.items;
      }

      return this.items.filter(
        (item) => item.category._id == this.selectedCategory._id
      );
    },
  },
  methods: {
    ...mapActions([
      "getLifeSchoolCategories",
      "addLifeSchoolCategory",
      "getLifeSchoolItems",
    ]),
    showDialog() {
      this.newCategoryName = "";
      this.$refs.overlay.classList.add("show");
    },
    dismiss() {
      this.$refs.overlay.classList.remove("show");
    },
    addCategory() {
      this.addLifeSchoolCategory({
        name: this.newCategoryName,
      })
        .then(() => {
          this.dismiss();
          this.refresh();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    refresh() {
      this.getLifeSchoolCategories()
        .then((categories) => {
          this.categories = categories;
        })
        .catch((error) => {
          console.log(error);
        });

      this.getLifeSchoolItems()
        .then((items) => {
          this.items = items;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>
